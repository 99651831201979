function classActive(el, name, alias, classNames) {
  classNames.forEach(className => {
    if (className === name || className === alias) {
      name && ((el.classList.contains(name) || el.classList.contains(alias)) || el.classList.add(name))
    } else {
      el.classList.contains(className) && el.classList.remove(className)
    }
  })
}
function allOffsetTop(el, top = 0) {
  return el.offsetParent ? allOffsetTop(el.offsetParent, el.offsetTop + top) : el.offsetTop + top
}

export default {
  name: 'scrollToggle',
  init(el, binding, vnode, callback) {
    binding.value && vnode.context.$nextTick(() => {
      const data = {
        minOffsetTop: allOffsetTop(el),
        maxOffsetTop: allOffsetTop(el) + el.clientHeight,
        classList: new Array(4),
        offsetScale: 1 / 2,
        oldScrollTop: 0,
        offsetSpace: 0
      }
      if (binding.value.length >= 4) {
        data.classList.splice(0, 4, ...binding.value.slice(0, 4))
        if (typeof binding.value[4] === 'number') data.offsetScale = binding.value[4]
      } else if (binding.value.length >= 2 && typeof binding.value[1] === 'string') {
        data.classList.splice(0, 2, ...binding.value.slice(0, 2))
        data.classList.copyWithin(2, 0)
        if (typeof binding.value[2] === 'number') data.offsetScale = binding.value[2]
      } else {
        data.classList.fill(binding.value[0])
        if (typeof binding.value[1] === 'number') data.offsetScale = binding.value[1]
      }
      binding._data = data
      typeof callback === 'function' && callback()
    })
  },
  inserted(el, binding, vnode) {
    binding.resizeFn = function() {
      binding.def.init(el, binding, vnode)
    }
    binding.scrollFn = function() {
      if (!binding._data) return
      const data = binding._data
      const scrollY = this.pageYOffset || this.scrollY
      const minScrollTop = scrollY
      const maxScrollTop = scrollY + this.innerHeight
      data.offsetSpace = data.offsetSpace || (el.clientHeight < this.innerHeight ? el.clientHeight * data.offsetScale : this.innerHeight * data.offsetScale)
      data.oldScrollTop = data.oldScrollTop || minScrollTop
      if (minScrollTop >= data.oldScrollTop) {
        if (minScrollTop >= data.maxOffsetTop - data.offsetSpace) {
          classActive(el, data.classList[3], data.classList[1], data.classList)
        } else if (maxScrollTop >= data.minOffsetTop + data.offsetSpace) {
          classActive(el, data.classList[0], data.classList[2], data.classList)
        }
      } else {
        if (maxScrollTop < data.minOffsetTop + data.offsetSpace) {
          classActive(el, data.classList[1], data.classList[3], data.classList)
        } else if (minScrollTop < data.maxOffsetTop - data.offsetSpace) {
          classActive(el, data.classList[2], data.classList[0], data.classList)
        }
      }
      data.oldScrollTop = minScrollTop
    }
    window.addEventListener('resize', binding.resizeFn)
    window.addEventListener('scroll', binding.scrollFn)
    binding.def.componentUpdated(el, binding, vnode)
  },
  componentUpdated(el, binding, vnode) {
    binding.def.init(el, binding, vnode, () => {
      typeof binding.scrollFn === 'function' && binding.scrollFn.call(window)
    })
  },
  unbind(el, binding) {
    window.removeEventListener('resize', binding.resizeFn)
    window.removeEventListener('scroll', binding.scrollFn)
  }
}

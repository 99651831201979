<template>
  <div :class="offset ? 'header-seat header-offset margin-top' : ''" class="banner-container">
    <slot name="before" />
    <swiper :options="swiperOptions" :class="offset ? 'header-offset bottom' : ''" class="swiper">
      <slot />
    </swiper>
    <div v-if="showPagination" :class="offset ? 'header-offset bottom' : ''" class="banner-pagination" />
    <slot name="after" />
  </div>
</template>

<script>
export default {
  name: 'BannerSwiper',
  props: {
    offset: {
      type: Boolean,
      default: true
    }
  },
  data() {
    const self = this
    return {
      showPagination: true,
      swiperOptions: {
        effect: 'fade',
        speed: 1200,
        followFinger: false,
        autoplay: {
          delay: 20000
        },
        pagination: {
          el: '.banner-pagination',
          clickable: true
        },
        on: {
          init() {
            self.showPagination = this.slides.length > 1
            self.swiperEnter(this)
          },
          transitionStart() {
            self.swiperLeave(this)
          },
          transitionEnd() {
            self.swiperEnter(this)
          }
        }
      }
    }
  },
  methods: {
    swiperEnter(swiper) {
      const activeSlide = swiper.slides.eq(swiper.activeIndex)
      activeSlide.removeClass('leave')
      activeSlide.addClass('enter')
    },
    swiperLeave(swiper) {
      for (let index = 0; index < swiper.slides.length; index++) {
        const slide = swiper.slides.eq(index)
        slide.removeClass('enter')
        slide.addClass('leave')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.banner-container{
  position: relative;
  .swiper{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 0;
    width: 100%;
    ::v-deep .swiper-slide{
      position: relative;
      z-index: 0;
      overflow: hidden;
      transition-delay: 1.2s;
      &.enter{
        z-index: 1;
      }
    }
  }
  ::v-deep .banner-pagination{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    display: flex;
    margin-top: 20px;
    justify-content: center;
    .swiper-pagination-bullet{
      width: 10px;
      height: 10px;
      border-radius: 5px;
      opacity: 1;
      outline: none;
      background: $themeBgColor;
      transition: all .6s;
      transition-delay: 1.2s;
      & + .swiper-pagination-bullet{
        margin-left: 10px;
      }
      &.swiper-pagination-bullet-active{
        width: 20px;
        background: $themeColorActive;
      }
    }
  }
}
</style>
